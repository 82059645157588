import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import api from "../api/api";
import { useNavigate } from 'react-router-dom'

/**
 * Sets base layout for dashboard - setting a navbar on top of layout
 * with the main content consisting of the layout component's children,
 * directly under navbar. Handle auth in wrapper.
 */
function BaseLayout({ children }) {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [teamId, setTeamId] = useState()
  const [teamSearchFailed, setTeamSearchFailed] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoading) {
      if(isAuthenticated) {
        const teamId = localStorage.getItem('teamId')
        if(teamId) {
          setTeamId(teamId)
        }

        api.getTeams({ userId: user.email }).then((response) => {
          const teams = response.data
          if (teams.length === 0) {
            navigate('/your-team')
            return
          }

          let invited_team_id = null
          teams.map((team) => {
            team.members.map((member) => {
              if(member.user_id === user.email && member.status === 'invited') {
                invited_team_id = team._id.$oid
                return
              }
            })
            return
          })

          if(invited_team_id) {
            localStorage.setItem('teamId', invited_team_id)
            setTeamId(invited_team_id)
            navigate('/your-team')
            return
          }

          // no invites found. check if active membership on any
          let active_team_id = null
          teams.map((team) => {
            team.members.map((member) => {
              if(member.user_id === user.email && member.status === 'active') {
                active_team_id = team._id.$oid
                return
              }
            })
          })

          if(active_team_id) {
            localStorage.setItem('teamId', active_team_id)
            setTeamId(active_team_id)
            navigate('/your-team')
            return
          }
        })
        .catch((err) => {
          console.log(err)
        }) 
      }
    }
  }, [teamId])

  const renderChildren = () => {
    return React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        teamId: teamId
      })
    })    
  }

  return (
    <>
      <div className="d-flex flex-row">
        <main className="main h-100 d-flex" style={{ width: '100%' }}>
          {renderChildren()}
        </main>
      </div>
    </>
  );
}

export default BaseLayout;
